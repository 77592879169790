import packageInfo from '../../package.json';

const uri = location.origin;
const msalConfig = {
  clientId: '6e1b9843-219a-4e35-9556-c5716e0961f2',
  authority: 'https://login.microsoftonline.com/9a3c2f68-d5b5-4769-b2f8-8d6fe142b164',
  redirectUri: uri,
  postLogoutRedirectUri: uri
}
const apiConfig = {
  scopes: ['user.read']
}
const isIE = window.navigator.userAgent.indexOf('MSIE ') > -1 || window.navigator.userAgent.indexOf('Trident/') > -1;

export const environment = {
  version: `dev ${packageInfo.version}`,
  auth: {
    topSignature: {
      requiredGroups: [
        '/TB-topSignature-Dev',
        '/S-TB-topSignature-Dev'
      ]
    },
    topSearch: {
      requiredGroups: [
        '/TB-topSearch-Dev',
        '/S-TB-topSearch-Dev'
      ]
    },
    topAdvert: {
      requiredGroups: [
        '/TB-topAdvert-Dev',
        '/S-TB-topAdvert-Dev'
      ]
    },
    topCompanies: {
      requiredGroups: [
        '/TB-topCompanies-Dev',
        '/S-TB-topCompanies-Dev'
      ]
    }
  },
  production: false,
  language: 'de',
  locale: 'de',
  asyncTimeOut: 30000,
  graphConnectorApi: 'http://t-muc-dn-03.topitservices.com:8098/api/azure/graph/user/',
  // graphConnectorApi: 'https://topbasegateway-api-dev.web.toptecs.systems/api/azure/graph/user/',
  webComponents: {
    topSignatureUrl: 'https://topdashboard.wavecdn.net/webComponents/dev/topSignature/topSignature.js',
    topSearchUrl: 'https://topdashboard.wavecdn.net/webComponents/dev/topSearch/topSearch.js',
    topAdvertUrl: 'https://topdashboard.wavecdn.net/webComponents/dev/topAdvert/topAdvert.js',
    topPeopleUrl: 'https://topdashboard.wavecdn.net/webComponents/dev/topPeople/topPeople.js',
    cssUrl: 'https://topdashboard.wavecdn.net/webComponents/dev/',
    lcsKrones: 'https://signstagingapi.top-itservices.systems:443/signApi/v1/webCom/lcsKronesDev'
  },
  idleTimeOut: 28800, // after 8 hours
  warningTime: 300, // 5 minutes
  pingToken: 120, // every 2 minutes
  uri: uri,
  msalConfig: msalConfig,
  isIE: isIE,
  apiConfig: apiConfig
};
