{
  "name": "top-dashboard",
  "version": "2.0.0",
  "scripts": {
    "ng": "ng",
    "start": "ng serve --port 4200 --live-reload true",
    "start-stag": "ng serve --port 4200 --live-reload true --configuration=staging",
    "live-server": "live-server dist/topDashboard --port=4200 --host=localhost",
    "build": "ng build",
    "build:prod": "ng build --configuration=production",
    "build:stag": "ng build --configuration=staging",
    "build:dev": "ng build --configuration=development --source-map=true",
    "test": "ng test",
    "lint": "ng lint",
    "e2e": "ng e2e"
  },
  "private": true,
  "dependencies": {
    "@angular-extensions/elements": "^18.2.1",
    "@angular/animations": "^18.2.8",
    "@angular/cdk": "^18.2.9",
    "@angular/common": "^18.2.8",
    "@angular/compiler": "^18.2.8",
    "@angular/core": "^18.2.8",
    "@angular/forms": "^18.2.8",
    "@angular/material": "^18.2.9",
    "@angular/platform-browser": "^18.2.8",
    "@angular/platform-browser-dynamic": "^18.2.8",
    "@angular/router": "^18.2.8",
    "@azure/msal-angular": "^3.0.25",
    "@azure/msal-browser": "^3.26.1",
    "@ngx-translate/core": "^15.0.0",
    "@ngx-translate/http-loader": "^8.0.0",
    "@webcomponents/custom-elements": "^1.6.0",
    "angular-user-idle": "^4.0.0",
    "rxjs": "^7.5.0",
    "zone.js": "~0.14.10"
  },
  "devDependencies": {
    "@angular-devkit/build-angular": "^18.2.9",
    "@angular/cli": "^18.2.9",
    "@angular/compiler-cli": "^18.2.8",
    "@angular/language-service": "^18.2.8",
    "@types/jasmine": "^5.1.4",
    "@types/jasminewd2": "^2.0.13",
    "@types/node": "^18.15.10",
    "jasmine-core": "^5.4.0",
    "jasmine-spec-reporter": "^7.0.0",
    "ts-node": "^10.2.1",
    "typescript": "^5.4.5"
  }
}
