import { ApplicationConfig, importProvidersFrom, provideZoneChangeDetection } from '@angular/core';
import { RouterModule} from '@angular/router';
import { HTTP_INTERCEPTORS, HttpClient, provideHttpClient, withFetch, withInterceptorsFromDi } from '@angular/common/http';
import { MSAL_GUARD_CONFIG, MSAL_INSTANCE, MSAL_INTERCEPTOR_CONFIG, MsalBroadcastService, MsalGuard, MsalInterceptor, MsalService } from '@azure/msal-angular';
import { MSALGuardConfigFactory, MSALInstanceFactory, MSALInterceptorConfigFactory } from './app.factory';
import { provideNoopAnimations } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { LazyElementModuleOptions, LazyElementsModule } from '@angular-extensions/elements';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';

const options: LazyElementModuleOptions = {
  elementConfigs: [
    {tag: 'top-signature-dashboard', url: environment.webComponents.topSignatureUrl},
    {tag: 'top-search-dashboard', url: environment.webComponents.topSearchUrl},
    {tag: 'top-advert', url: environment.webComponents.topAdvertUrl},
    {tag: 'top-people-show', url: environment.webComponents.topPeopleUrl},
    {tag: 'top-lcs-krones', url: environment.webComponents.lcsKrones}
  ]
};

export function HttpLoaderFactory(http: HttpClient) {
  const translateUrl = 'assets/i18n/';
  return new TranslateHttpLoader(http, translateUrl, '.json');
}
export const appConfig: ApplicationConfig = {
  providers: [
    provideZoneChangeDetection({ eventCoalescing: true }),
    importProvidersFrom(
      AppRoutingModule,
      BrowserModule,
      RouterModule.forRoot([]),
      LazyElementsModule.forFeature(options),
      TranslateModule.forRoot({
        defaultLanguage: 'de',
        loader: {
          provide: TranslateLoader,
          useFactory: HttpLoaderFactory,
          deps: [HttpClient],
        }
      }),
    ),
    provideNoopAnimations(),
    provideHttpClient(withInterceptorsFromDi(), withFetch()),
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true
    },
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: MSALGuardConfigFactory
    },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: MSALInterceptorConfigFactory
    },
    MsalService,
    MsalGuard,
    MsalBroadcastService
  ],
};
