import {
  AfterViewChecked,
  ChangeDetectorRef,
  Component,
  CUSTOM_ELEMENTS_SCHEMA,
  Inject,
  OnDestroy,
  OnInit,
  ViewEncapsulation
} from '@angular/core';
import {TranslateModule, TranslateService} from '@ngx-translate/core';
import {environment} from '../environments/environment';
import {CommonModule, DOCUMENT, Location} from '@angular/common';
import {MSAL_GUARD_CONFIG, MsalBroadcastService, MsalGuardConfiguration, MsalModule, MsalService} from '@azure/msal-angular';
import {InteractionStatus, RedirectRequest} from '@azure/msal-browser';
import {filter, takeUntil} from 'rxjs/operators';
import {Observable, Subject} from 'rxjs';
import {TabProperties} from './models/tab-properties';
import {TopLanguageService} from './core/shared/language.service';
import {UserService} from './core/services/user.service';
import {ActivatedRoute} from '@angular/router';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatIconModule} from '@angular/material/icon';
import {MatToolbarModule} from '@angular/material/toolbar';
import {FormsModule} from '@angular/forms';
import {MatTabsModule} from '@angular/material/tabs';
import {MatMenuModule} from '@angular/material/menu';
import {MatSelectModule} from '@angular/material/select';
import {MatListModule} from '@angular/material/list';
import {LazyElementsModule} from '@angular-extensions/elements';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {MatButtonModule} from '@angular/material/button';
import {MatDialogModule} from '@angular/material/dialog';
import {MatCardModule} from '@angular/material/card';


@Component({
  standalone: true,
  imports: [
    MatSidenavModule,
    MatIconModule,
    MatToolbarModule,
    CommonModule,
    FormsModule,
    TranslateModule,
    MatTabsModule,
    MatMenuModule,
    TranslateModule,
    MatSelectModule,
    MatListModule,
    LazyElementsModule,
    MsalModule,
    MatSnackBarModule,
    MatButtonModule,
    MatDialogModule,
    MatCardModule
  ],
  selector: 'top-dashboard',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})

export class AppComponent implements OnInit, OnDestroy, AfterViewChecked {
  public version: string;
  public userId: string;
  public authorization: string;
  public groups: string[];
  public isIframe = false;
  public loginDisplay = false;
  public selectedTab = 0;
  public currentTabAttribute = '';
  public selectedLanguage = 'de';
  public tabs: TabProperties[] = [];
  public emailAddress: string;

  public currentLanguage$: Observable<string> = this.languageService.current;
  public toolboxItems = [
    {name: 'topNet', link: 'https://topnet.refined.site'},
    {name: 'topTicket', link: 'https://topticket.refined.site/?autoLogin=true'},
    {name: 'Arbeitnehmer online', link: 'https://apps.datev.de/ano/'},
    {name: 'Egencia', link: 'https://www.egencia.de/de/'},
    {
      name: 'Haufe Zeugnis Manager',
      link: 'https://zeugnismanager.haufe.de/haz/page/jobReferenceRequest.html?secToken=32b1aa22-5d26-4218-81dc-37689157ecf8&loc=av#/cdb7334a-dc41-4bfa-993f-d1a311923d46'
    },
    {name: 'sam', link: 'https://top-itservices.secova.de/module/login/?returnTo=app2%2Fadmin'},
    {name: 'ABLE-Webshop', link: 'https://able2.shopdriver.de/Top-IT'},
    {name: 'WinTrip', link: 'https://cloud1357.iuk-cloud.com/topit446/'},
    {name: 'Zeiterfassung (ExPo)', link: 'https://timerec.top-itservices.com/#/timeRecording/welcome'}
  ];

  public searchportals = [
    {name: 'Agentur für Arbeit', link: 'https://www.arbeitsagentur.de/bewerberboerse/'},
    {name: 'freelance.de', link: 'https://www.freelance.de/login.php'},
    {name: 'freelancermap', link: 'https://www.freelancermap.de/'},
    {name: 'Indeed', link: 'https://de.indeed.com/'},
    {name: 'LinkedIn', link: 'https://www.linkedin.com/feed/'},
    {name: 'Stepstone', link: 'https://www.stepstone.de/'},
    {name: 'XING', link: 'https://www.xing.com/jobs/find'}
  ];

  public boxes = [
    {name: 'BOXES.TOOLBOX.NAME', headline: 'BOXES.TOOLBOX.HEADLINE', items: this.toolboxItems},
    {name: 'BOXES.SEARCHPORTAL.NAME', headline: 'BOXES.SEARCHPORTAL.HEADLINE', items: this.searchportals}
  ];

  public components: string[] = ['topSearch', 'topAdvert'];
  private readonly _destroying$ = new Subject<void>();

  constructor(@Inject(DOCUMENT) private document: Document,
              @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
              private cdRef: ChangeDetectorRef,
              private translate: TranslateService,
              private broadcastService: MsalBroadcastService,
              private authService: MsalService,
              private languageService: TopLanguageService,
              private userService: UserService,
              private route: ActivatedRoute,
              private location: Location
  ) {
    this.translate.setDefaultLang('en');
    this.translate.use(environment.language);
  }

  ngOnInit() {
    this.version = environment.version;
    this.loadCss(environment.webComponents.cssUrl, 'topSearch', 'assets/scss/top-search.css');
    this.loadCss(environment.webComponents.cssUrl, 'topAdvert', 'assets/scss/top-advert.css');
    this.loadCss(environment.webComponents.cssUrl, 'topSignature', 'assets/scss/top-signature.css');
    this.isIframe = window !== window.parent && !window.opener;
    this.authService.handleRedirectObservable().subscribe();
    this.broadcastService.inProgress$
      .pipe(
        filter((status: InteractionStatus) => status === InteractionStatus.None),
        takeUntil(this._destroying$)
      )
      .subscribe(() => {
        this.setLoginDisplay();
        if (this.loginDisplay) {
          this.setActiveAccount();
          this.emailAddress = this.authService.instance.getActiveAccount().username;
          this.tabs.push(new TabProperties('', 'Dashboard', 'dashboard', 0, '', 0));
          this.routeToComponentIfParameterExists();
          this.currentTabAttribute = `dashboard`;
        } else {
          this.login();
        }
      });
  }

  ngOnDestroy() {
    this._destroying$.next(undefined);
    this._destroying$.complete();
  }

  ngAfterViewChecked() {
    this.currentLanguage$ = this.languageService.current;
    this.cdRef.detectChanges();
  }

  useLanguage(language: string) {
    this.selectedLanguage = language;
    this.languageService.use(language);
    this.cdRef.detectChanges();
  }

  public loadWebComponent(event) {
    const param = JSON.parse(event);
    this.tabs.push(new TabProperties(param.url, param.label, param.attributeName, this.tabs.length - 1, '',0));
    this.selectedTab = this.tabs.length - 1;
  }

  public checkAccessRights(module: string): boolean {
    for (const group of environment.auth[module].requiredGroups) {
      if (this.groups) {
        return this.groups.some(entry => entry === group);
      }
    }
  }

  public setTabDashboard() {
    this.selectedTab = 0;
  }

  public closeTab(index) {
    this.tabs.splice(index, 1);
  }

  public logout(): void {
    this.authService.logout();
  }

  public openToolInNewWindow(link: string) {
    window.open(link, '_blank');
  }

  private login(): void {
    if (this.msalGuardConfig.authRequest) {
      this.authService.loginRedirect({...this.msalGuardConfig.authRequest} as RedirectRequest);
    } else {
      this.authService.loginRedirect();
    }
  }

  private setLoginDisplay(): void {
    this.loginDisplay = this.authService.instance.getAllAccounts().length > 0;
  }

  private setActiveAccount(): void {
    const activeAccount = this.authService.instance.getActiveAccount();
    if (!activeAccount) {
      const accounts = this.authService.instance.getAllAccounts();
      this.authService.instance.setActiveAccount(accounts[0]);
    }
    this.userService.getRecIdByMailId(this.authService.instance.getActiveAccount().username).subscribe(userId => this.userId = userId);
    this.userService.getGroupsByMailId(this.authService.instance.getActiveAccount().username).subscribe(groups => this.groups = groups);
  }

  // private setUserId(): void {
  //   this.userService.getRecIdByMailId('').subscribe(userId => this.userId = userId);
  // }

  private loadCss(url: string, module: string, fileName: string): void {
    const headElement = this.document.getElementsByTagName('head')[0];
    const newLinkElement = this.document.createElement('link');
    newLinkElement.rel = 'stylesheet';
    newLinkElement.href = `${url}${module}/${fileName}`;
    headElement.appendChild(newLinkElement);
  }

  private routeToComponentIfParameterExists() {
    const urlPathSegments = this.location.path().split('/');
    this.components.forEach(component => {
      if (urlPathSegments[urlPathSegments.length - 2] === component) {
        this.tabs.push(new TabProperties(
          component, component, component, this.tabs.length - 1, urlPathSegments[urlPathSegments.length - 1],
          component === 'topSearch' &&
          (!isNaN(+urlPathSegments[urlPathSegments.length - 1]) || urlPathSegments[urlPathSegments.length - 1] === 'personSearch')
            ? 1 : 0));
        this.selectedTab = this.tabs.length - 1;
      }
    });
  }
}
